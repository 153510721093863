import React, { useState, useEffect } from 'react';
import Layout from "components/Layout";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { refreshAuth } from 'features/user';
import { qrAccountView } from 'features/qr/QrAccountView';
import { qrAccountCreate } from 'features/qr/QrAccountCreate';
import ProductLabelFormAlt from './forms/ProductLabelFormAlt';
import DatalistAltCS from './forms/DatalistAltCS';
import { viewAllQrLabelsCall } from 'features/qrLabel/viewAllQrLabels';
import { useTranslation } from 'react-i18next';
import ModalWithChildren from 'components/ModalWithChildren';
import FormDetails from './forms/FormDetails';

const DashboardPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        isAuthenticated,
        user,
        loading,
        qr_account,
        qr_account_created,
        qr_account_not_exist,
        qr_label_list,
        qr_label_list_no_data,
        qr_label_list_have_change
    } = useSelector(state => state.user);

    const [showModal, setShowModal] = useState(false);
    const [content, setContent] = useState(0);
    const [masked_id, setMasked_id] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {
        const refreshUser = () => {
            dispatch(refreshAuth());
        };

        const intervalId = setInterval(refreshUser, 10 * 60 * 1000); // 10 minutes in milliseconds
        return () => clearInterval(intervalId);
    }, [dispatch]);

    useEffect(() => {
        if (!qr_account && !qr_account_not_exist) {
            dispatch(qrAccountView());
        }
    }, [dispatch, qr_account, qr_account_not_exist, qr_account_created]);

    useEffect(() => {
        if (user && !qr_label_list && !qr_label_list_no_data) {
            const payload = { id: user.id };
            dispatch(viewAllQrLabelsCall(payload));
        }
    }, [dispatch, qr_label_list, qr_label_list_no_data, user]);

    useEffect(() => {
        if (user && qr_label_list_have_change) {
            const payload = { id: user.id };
            dispatch(viewAllQrLabelsCall(payload));
        }
    }, [qr_label_list_have_change, dispatch, user]);

    useEffect(() => {
        if (qr_label_list) {
            setData(qr_label_list.data);
        }
    }, [qr_label_list]);

    const createQrAcc = () => {
        dispatch(qrAccountCreate());
    };

    const showModalForm_1 = () => {
        setShowModal(true);
        setContent(1);
    };

    const showModalForm_2 = (maskedId) => {
        setShowModal(true);
        setContent(2);
        setMasked_id(maskedId);
    };

    const hideModal = () => {
        setShowModal(false);

    };

    const displayedContent = () => {
        if (!showModal) return;
        switch (content) {
            case 1:
                return <ProductLabelFormAlt action={setShowModal} />;
            case 2:
                return <FormDetails data={data} masked_id={masked_id} close_modal={hideModal} />;
            default:
                setShowModal(false);
                setContent(0);
                break;
        }
    };

    if (!isAuthenticated && !loading && user === null) {
        return <Navigate to='/login' />;
    }

    return (
        <Layout title='Auth Site | Dashboard' content='Dashboard page'>
            {loading || user === null ? (
                <div className="spinner-border text-primary mt-4" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            ) : (
                <div className="container" style={{ maxWidth: "1000px" }}>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card" style={{ width: "100%", padding: "20px", backgroundColor: "#e6fff2" }}>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <p className="title">{t('user')}:</p>
                                        <ul style={{ fontSize: "14px" }}>
                                            <li>{t('first_name')}: {user.first_name}</li>
                                            <li>{t('last_name')}: {user.last_name}</li>
                                            <li>{t('email')}: {user.email}</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <p className="title">{t('qr_account')}:</p>
                                        {qr_account_not_exist ? (
                                            <button onClick={createQrAcc} className="btn btn-primary mt-4">{t('create_qr_account')}</button>
                                        ) : qr_account ? (
                                            <ul style={{ fontSize: "14px" }}>
                                                <li>{t('creation_date')}: {new Date(qr_account.date_created).toLocaleDateString()}</li>
                                                <li>{t('valid_until')}: {new Date(qr_account.date_end).toLocaleDateString()}</li>
                                                <li>{t('max_qr_codes')}: {qr_account.qr_count}</li>
                                                <li>{t('account_active')}: {qr_account.enabled ? `${t('yes')}` : `${t('no')}`}</li>
                                                {!qr_account.enabled && `${t('account_not_active')}`}
                                            </ul>
                                        ) : (
                                            <ul style={{ fontSize: "14px" }}>
                                                <li>{t('creation_date')}:</li>
                                                <li>{t('valid_until')}:</li>
                                                <li>{t('max_qr_codes')}:</li>
                                                <li>{t('account_active')}:</li>
                                            </ul>
                                        )}
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <p className="title">{t('impressum')}:</p>
                                        {qr_account_not_exist ? (
                                            <ul style={{ fontSize: "14px" }}>
                                                <li>{t('company')}</li>
                                                <li>{t('address')}</li>
                                                <li>{t('oib')}</li>
                                                <li>{t('phone')}</li>
                                                <li>{t('email')}</li>
                                            </ul>
                                        ) : qr_account ? (
                                            <ul style={{ fontSize: "14px" }}>
                                                <li>{qr_account.company_name}</li>
                                                <li>{qr_account.company_address}</li>
                                                <li>{qr_account.company_oib}</li>
                                                <li>{qr_account.company_phone}</li>
                                                <li>{qr_account.company_email}</li>
                                            </ul>
                                        ) : (
                                            <ul style={{ fontSize: "14px" }}>
                                                <li>{t('company')}</li>
                                                <li>{t('address')}</li>
                                                <li>{t('oib')}</li>
                                                <li>{t('phone')}</li>
                                                <li>{t('email')}</li>
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!qr_account_not_exist && qr_account && qr_account.qr_count && (
                        <DatalistAltCS data={data} num={qr_account.qr_count} add={() => showModalForm_1()} det={showModalForm_2} />
                    )}
                </div>
            )}
            <ModalWithChildren
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                renderSave={false}
                renderDelete={false}
                style={{ maxWidth: "800px", minHeight: "600px" }}>
                {displayedContent()}
            </ModalWithChildren>
        </Layout>
    );
};

export default DashboardPage;
