import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetRegistered, login } from "features/user";
import { Navigate } from "react-router-dom";
import Layout from "components/Layout";
import './App.css';
import { useTranslation } from 'react-i18next';

const LoginPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { loading, isAuthenticated, registered, userFound, loadingUser } = useSelector(state => state.user);

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    useEffect(() => {
        if (registered) {
            dispatch(resetRegistered());
        }

    }, [registered]);

    const { email, password } = formData;

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

    };


    const onSubmit = e => {
        e.preventDefault();
        dispatch(login({ email, password }));

    };

    if (isAuthenticated && userFound) {
        return <Navigate to='/dashboard' />;
    }

    return (
        <Layout title='Auth Site | Login' content='Login page'>

            <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh", flexDirection: "column" }}>
                <form className="card"
                    action=""
                    style={{ padding: "20px", maxWidth: "450px" }}
                    onSubmit={onSubmit}>
                    <p className="title">{t('login')}</p>
                    <div className="input-field">
                        <input className="input"
                            type="email"
                            name="email"
                            onChange={onChange}
                            value={email}
                            required
                        />
                        <label className="label" htmlFor="input">Email:</label>
                    </div>
                    <div className="input-field">
                        <input className="input"
                            type="password"
                            name="password"
                            autoComplete="current-password"
                            onChange={onChange}
                            value={password}
                            required
                        />
                        <label className="label" htmlFor="input">{t('password')}:</label>
                    </div>
                    <a>{t('forget_your_password')}</a>
                    {loading || loadingUser ?
                        (
                            <div className="spinner-border text-primary mt-2" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            <button className="btn btn-primary mt-4"> Log in </button>
                        )
                    }
                </form>

                <div className="text-container" style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "60vh", flexDirection: "column" }}>
                    <div className="text" >
                        <h2>{t('ltt')}</h2>
                        <p>
                            {t('lttxt1')}
                        </p>
                        <p>
                            {t('lttxt2')}
                        </p>
                        <p>
                            Contact email: danijel@ordonovus.com
                        </p>
                    </div>
                </div>

            </div>
        </Layout>
    );
};

export default LoginPage;