// src/components/LanguageSwitcher.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLanguage } from 'features/user';

const LanguageSwitcher = () => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const { language } = useSelector(state => state.user);
    const [lan, setLan] = useState(language ? language : "hr");

    useEffect(() => {
        const browserLanguage = navigator.language || navigator.userLanguage;
        if (browserLanguage.startsWith('hr') && language === '') {
            i18n.changeLanguage('hr');

        } else if (language === '') {
            i18n.changeLanguage('en');
        }

    }, [i18n]);

    useEffect(() => {
        if (language !== '' && language !== lan) {
            i18n.changeLanguage(language);
        }
    }, [dispatch, language]);

    const changeLanguageHc = (lng) => {
        i18n.changeLanguage(lng);
        dispatch(setLanguage(lng));
        setLan(lng);
    };

    console.log()

    return (
        <select name="switchbox" value={lan} onChange={(e) => changeLanguageHc(e.target.value)}>
            <option value="hr">Hr</option>
            <option value="en">En</option>
            {/* Add more languages here */}
        </select>
    );
};

export default LanguageSwitcher;
