//https://redux-toolkit.js.org/api/createSlice
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { qrAccountView } from './qr/QrAccountView';
import { qrAccountCreate } from './qr/QrAccountCreate';

import { createQrLabelCall } from './qrLabel/createQrLabel';
import { createBulkQrLabelCall } from './qrLabel/createBulkQrLabel';
import { deleteQrLabelCall } from './qrLabel/deleteQrLabel';
import { updateQrLabelCall } from './qrLabel/updateQrLabel';
import { viewAllQrLabelsCall } from './qrLabel/viewAllQrLabels';
import { publicViewQrLabelsCall } from './qrLabel/publicViewQrLabels';


//import { API_URL } from 'config/index';

//Action creator
//setupaj redux async thuk https://redux-toolkit.js.org/api/createAsyncThunk
//vraca
//'users/requestStatus/pending'
//'users/requestStatus/fulfilled'
//'users/requestStatus/rejected'
// umjesto ovoga { first_name, last_name, email, password } moze bit 'arg' pa arg proslijedis u stringify
export const register = createAsyncThunk(
    'users/register',
    async ({ first_name, last_name, email, password }, thunkAPI,) => {
        const body = JSON.stringify({
            first_name,
            last_name,
            email,
            password,
        });

        try {
            //const res = await fetch(`${API_URL}/api/users/register`, {
            const res = await fetch('/api/users/register', {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });

            const data = await res.json();

            if (res.status === 201) {
                //ovo ne koristimo, možemo dodat neki hndle za pujnejne usera
                return data;
            } else {
                console.log("USERS != 201 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

const getUser = createAsyncThunk('users/me', async (_, thunkAPI) => {
    //console.log("const getUser = createAsyncThunk('users/me'----------,,,,,,,,,,,,,--------");
    try {
        const res = await fetch('/api/users/me', {
            method: 'GET',
            headers: {
                Accept: 'application/JSON',
                'Content-Type': 'application/json'
            }
        });

        //console.log(res.json());
        const data = await res.json();

        //console.log("////////////////////////");
        //console.log(data);
        //console.log(res.status);

        if (res.status === 200) {
            return data;

        } else {
            return thunkAPI.rejectWithValue(data);
        }

    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data);
    }

});


export const login = createAsyncThunk(
    'users/login',
    async ({ email, password }, thunkAPI,) => {
        const body = JSON.stringify({
            email,
            password,
        });

        //routes/auth/login
        try {
            const res = await fetch('/api/users/login', {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });

            const data = await res.json();

            if (res.status === 200) {
                const { dispatch } = thunkAPI;
                //dohvati podatke o  korisniku
                dispatch(getUser());

                return data;
            } else {
                //console.log("login  thunkAPI.rejectWithValue(data)");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("login Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);


export const checkAuth = createAsyncThunk(
    'users/verify',
    async (_, thunkAPI) => {
        try {
            const res = await fetch('/api/users/verify', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
            });

            const data = await res.json();

            if (res.status === 200) {
                const { dispatch } = thunkAPI;

                dispatch(getUser());

                return data;
            } else {
                const { dispatch } = thunkAPI;
                dispatch(refreshAuth());
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const logout = createAsyncThunk(
    'users/logout',
    async (_, thunkAPI,) => {

        try {
            const res = await fetch('/api/users/logout', {  //routes/auth/login
                method: 'GET',
                headers: {
                    Accept: 'application/JSON',
                },
            });

            const data = await res.json();

            if (res.status === 200) {
                return data;

            } else {
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

export const refreshAuth = createAsyncThunk(
    'users/refresh',
    async (_, thunkAPI) => {
        try {
            const res = await fetch('/api/users/refresh', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                },
            });

            const data = await res.json();
            console.log("refresh - Refresham usera");

            if (res.status === 200) {
                console.log("refresh - Success");

                const { dispatch } = thunkAPI;
                dispatch(getUser());

                return data;
            } else {

                console.log("refresh - !Success");
                return thunkAPI.rejectWithValue(data);
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);

//ovo  proširi za datashit ako trebaš još  data
const initialState = {
    isAuthenticated: false,
    user: null,
    loading: false,
    registered: false,
    invalidUser: false,
    userFound: false,
    loadingUser: false,
    invalid: false,

    qr_account: null,
    qr_account_created: false,
    qr_account_not_exist: false,

    qr_label_list: null,
    qr_label_list_load: false,
    qr_label_list_no_data: false,

    qr_label_list_changes: 0,
    qr_label_list_have_change: false,

    public_label: [],
    no_public_label: false,

    //error: expandaj po potrebi
    language: '',
};


//sinkroni dispatchi
const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {

        //Synchronus action creator
        resetRegistered: state => {
            state.registered = false;
        },
        setLanguage: (state, action) => {
            state.language = action.payload;
        }

    },

    //poziv bez dodatnih funkcionalnosti
    extraReducers: builder => {
        builder
            .addCase(publicViewQrLabelsCall.pending, state => {
                state.qr_label_list_load = true;
                state.no_public_label = false;

            })
            .addCase(publicViewQrLabelsCall.fulfilled, (state, action) => {
                state.qr_label_list_load = false;
                state.public_label = action.payload;
            })
            .addCase(publicViewQrLabelsCall.rejected, state => {
                state.qr_label_list_load = false;
                state.no_public_label = true;

            })
            .addCase(createBulkQrLabelCall.pending, state => {
                state.qr_label_list_load = true;
                state.qr_label_list_changes = state.qr_label_list_changes + 1;

            })
            .addCase(createBulkQrLabelCall.fulfilled, (state, action) => {
                state.qr_label_list_load = false;
                state.qr_label_list_have_change = true;
                state.qr_label_list_changes = state.qr_label_list_changes - 1;
            })
            .addCase(createBulkQrLabelCall.rejected, state => {
                state.qr_label_list_load = false;
                state.qr_label_list_changes = state.qr_label_list_changes - 1;
            })
            .addCase(createQrLabelCall.pending, state => {
                state.qr_label_list_load = true;
                state.qr_label_list_changes = state.qr_label_list_changes + 1;

            })
            .addCase(createQrLabelCall.fulfilled, (state, action) => {
                state.qr_label_list_load = false;
                state.qr_label_list_have_change = true;
                state.qr_label_list_changes = state.qr_label_list_changes - 1;
            })
            .addCase(createQrLabelCall.rejected, state => {
                state.qr_label_list_load = false;
                state.qr_label_list_changes = state.qr_label_list_changes - 1;
            })
            .addCase(deleteQrLabelCall.pending, state => {
                state.qr_label_list_load = true;
                state.qr_label_list_changes = state.qr_label_list_changes + 1;

            })
            .addCase(deleteQrLabelCall.fulfilled, (state, action) => {
                state.qr_label_list_load = false;
                state.qr_label_list_have_change = true;
                state.qr_label_list_changes = state.qr_label_list_changes - 1;
            })
            .addCase(deleteQrLabelCall.rejected, state => {
                state.qr_label_list_load = false;
                state.qr_label_list_have_change = true;
                state.qr_label_list_changes = state.qr_label_list_changes - 1;
            })
            .addCase(updateQrLabelCall.pending, state => {
                state.qr_label_list_load = true;
                state.qr_label_list_changes = state.qr_label_list_changes + 1;

            })
            .addCase(updateQrLabelCall.fulfilled, (state, action) => {
                state.qr_label_list_load = false;
                state.qr_label_list_have_change = true;
                state.qr_label_list_changes = state.qr_label_list_changes - 1;
            })
            .addCase(updateQrLabelCall.rejected, state => {
                state.qr_label_list_load = false;
                state.qr_label_list_changes = state.qr_label_list_changes - 1;
            })
            .addCase(viewAllQrLabelsCall.pending, state => {
                state.qr_label_list_load = true;
                state.qr_label_list_changes = 0;
                state.qr_label_list_no_data = false;
                state.qr_label_list_have_change = false;
            })
            .addCase(viewAllQrLabelsCall.fulfilled, (state, action) => {
                state.qr_label_list_load = false;
                state.qr_label_list = action.payload;
            })
            .addCase(viewAllQrLabelsCall.rejected, state => {
                state.qr_label_list_load = false;
                state.qr_label_list_no_data = true;
            })
            .addCase(qrAccountView.pending, state => {
                state.loading = true;
                state.qr_account = null;
                state.qr_account_not_exist = false;
            })
            .addCase(qrAccountView.fulfilled, (state, action) => {
                state.loading = false;
                state.qr_account = action.payload;
            })
            .addCase(qrAccountView.rejected, state => {
                state.loading = false;
                state.qr_account_not_exist = true;
            })
            .addCase(qrAccountCreate.pending, state => {
                state.loading = true;
            })
            .addCase(qrAccountCreate.fulfilled, state => {
                state.loading = false;
                state.qr_account_created = true;
                state.qr_account_not_exist = false;
            })
            .addCase(qrAccountCreate.rejected, state => {
                state.loading = false;
            })
            .addCase(register.pending, state => {
                state.loading = true;
            })
            .addCase(register.fulfilled, state => {
                state.loading = false;
                state.registered = true;
            })
            .addCase(register.rejected, state => {
                state.loading = false;
            })
            .addCase(login.pending, state => {
                state.loading = true;
            })
            .addCase(login.fulfilled, state => {
                state.loading = false;
                state.isAuthenticated = true;
            })
            .addCase(login.rejected, state => {
                state.loading = false;
            })
            .addCase(getUser.pending, state => {
                state.loading = true;
                state.loadingUser = true;
                state.invalidUser = false;
                state.userFound = false;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.loading = false;
                state.loadingUser = false;
                state.userFound = true;
                state.user = action.payload;

            })
            .addCase(getUser.rejected, state => {
                state.loading = false;
                state.invalidUser = true;
                state.userFound = false;
                state.loadingUser = false;

            })
            .addCase(checkAuth.pending, state => {
                state.loading = true;
            })
            .addCase(checkAuth.fulfilled, state => {
                state.loading = false;
                state.isAuthenticated = true;
            })
            .addCase(checkAuth.rejected, state => {
                state.loading = false;
            })
            .addCase(logout.pending, state => {
                state.loading = true;
            })
            .addCase(logout.fulfilled, state => {
                state.loading = false;
                state.isAuthenticated = false;
                state.user = null;
            })
            .addCase(logout.rejected, state => {
                state.loading = false;
            })
            .addCase(refreshAuth.pending, state => {
                state.invalid = false;
            })
            .addCase(refreshAuth.rejected, state => {
                state.invalid = true;
                console.log("ERROR");
            })
            //----------------------------------------
            ;
    }
});

export const { resetRegistered, setLanguage } = userSlice.actions
export default userSlice.reducer



//const counterSlice = createSlice({
//    name: 'counter',
//    initialState,
//    reducers: {
//        increment(state) {
//            state.value++
//        },
//        decrement(state) {
//            state.value--
//        },
//        incrementByAmount(state, action) {
//            state.value += action.payload
//        },
//    },
//})

//async thunk za async
//const myNewActionCreate

//export const { increment, decrement, incrementByAmount } = counterSlice.actions
//export default counterSlice.reducer