import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { checkAuth } from "features/user";

import HomePage from "containers/HomePage"; //Zbog realativne rute
import DashboardPage from "containers/DashboardPage";
import LoginPage from "containers/LoginPage";
import RegisterPage from "containers/RegisterPage";
import NoPage from "containers/NoPage";
import ServiceDetailsPage from "containers/ServiceDetailsPage";
import Item from "containers/Item";
import ItemsAlt from "containers/ItemsAlt";
import './i18n'; // Import the i18n configuration

const App = () => {
    const dispatch = useDispatch();

    //kad se komponenta mounta, provjeri dali je aktivan cookie
    useEffect(() => {
        dispatch(checkAuth());
    }, []);

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/item/:id/:sig' element={<ItemsAlt />} />
                <Route path='/' element={<HomePage />} />
                <Route path='/dashboard' element={<DashboardPage />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path='/register' element={<RegisterPage />} />
                <Route path='/services' element={<ServiceDetailsPage />} />
                <Route path='/*' element={<NoPage />} />
            </Routes>
        </BrowserRouter>
    );
};


export default App;