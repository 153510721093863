import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import '../App.css';
import { createQrLabelCall } from 'features/qrLabel/createQrLabel';
import { createBulkQrLabelCall } from 'features/qrLabel/createBulkQrLabel';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';


const ProductLabelFormAlt = ({ action }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const t_eng = i18n.getFixedT('en');
    const { qr_account, qr_label_list, qr_label_list_load } = useSelector(state => state.user);
    const [lan, setLan] = useState("hr");

    const [formData, setFormData] = useState({
        name: "",
        allergens: "",
        energy_value: "",
        energy_value_kcal: "",
        fat: "",
        saturated_fat: "",
        carbohydrates: "",
        sugar: "",
        protein: "",
        salt: "",
        category: "",
        alcoholic_strength: "",
        provenance: "",
        producer_info: "",
        net_content: "",
        sugar_content: "",
        durability_date: "",
        ingredients: "",
        nutritional_info: "",
        company_name: "",
        company_address: "",
        company_oib: "",
        company_phone: "",
        company_email: "",
        sulfites: "",
        lang: "hr",

    });

    const [formDataEng, setFormDataEng] = useState({
        name: "",
        allergens: "",
        energy_value: "",
        energy_value_kcal: "",
        fat: "",
        saturated_fat: "",
        carbohydrates: "",
        sugar: "",
        protein: "",
        salt: "",
        category: "",
        alcoholic_strength: "",
        provenance: "",
        producer_info: "",
        net_content: "",
        sugar_content: "",
        durability_date: "",
        ingredients: "",
        nutritional_info: "",
        company_name: "",
        company_address: "",
        company_oib: "",
        company_phone: "",
        company_email: "",
        sulfites: "",
        lang: "en",

    });

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (e.target.name === "name"
            || e.target.name === "energy_value"
            || e.target.name === "energy_value_kcal"
            || e.target.name === "fat"
            || e.target.name === "saturated_fat"
            || e.target.name === "carbohydrates"
            || e.target.name === "sugar"
            || e.target.name === "protein"
            || e.target.name === "salt") {
            setFormDataEng({ ...formDataEng, [e.target.name]: e.target.value });
        }
    };

    const onChangeEng = e => {
        setFormDataEng({ ...formDataEng, [e.target.name]: e.target.value });

        if (e.target.name === "name"
            || e.target.name === "energy_value"
            || e.target.name === "energy_value_kcal"
            || e.target.name === "fat"
            || e.target.name === "saturated_fat"
            || e.target.name === "carbohydrates"
            || e.target.name === "sugar"
            || e.target.name === "protein"
            || e.target.name === "salt") {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const showWarning = () => {
        if (qr_label_list && qr_account) {
            const num = Number(qr_account.qr_count);
            const num2 = Number(qr_label_list.data.length);
            return num === num2;
        }
        return false;
    }

    const handleSave = () => {
        if (qr_label_list && qr_account) {
            const num = Number(qr_account.qr_count);
            const num2 = Number(qr_label_list.data.length);
            if (num === num2) return;
        }

        const payload = [formData, formDataEng];
        action(false);

        dispatch(createBulkQrLabelCall(payload));
        setFormData({
            name: "",
            allergens: "",
            energy_value: "",
            energy_value_kcal: "",
            fat: "",
            saturated_fat: "",
            carbohydrates: "",
            sugar: "",
            protein: "",
            salt: "",
            category: "",
            alcoholic_strength: "",
            provenance: "",
            producer_info: "",
            net_content: "",
            sugar_content: "",
            durability_date: "",
            ingredients: "",
            nutritional_info: "",
            sulfites: "",
            lang: "hr"
        });

        setFormDataEng({
            name: "",
            allergens: "",
            energy_value: "",
            energy_value_kcal: "",
            fat: "",
            saturated_fat: "",
            carbohydrates: "",
            sugar: "",
            protein: "",
            salt: "",
            category: "",
            alcoholic_strength: "",
            provenance: "",
            producer_info: "",
            net_content: "",
            sugar_content: "",
            durability_date: "",
            ingredients: "",
            nutritional_info: "",
            sulfites: "",
            lang: "en"
        });
    };

    const changeLanguageHc = (lng) => {
        setLan(lng);
    };

    const showProperInputs = (lng) => {

        if (lng === "en") return show_En_Input();
        return show_Hr_Input();
    };

    const show_En_Input = () => {
        return (<><form>
            <div className="row mb-3">
                <div className="col-12" >

                    <div className="form-group">
                        <label htmlFor="name">{t_eng('product_name_uppercase')}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            onChange={onChangeEng}
                            value={formDataEng.name}
                            required
                        />
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-8" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label htmlFor="ingredients">{t_eng('composition_uppercase')}</label>
                        <textarea
                            className="form-control"
                            id="ingredients"
                            name="ingredients"
                            onChange={onChangeEng}
                            value={formDataEng.ingredients}
                            rows="5"
                        />
                    </div>
                </div>
                <div className="col-md-4" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label>{t_eng('details_uppercase')}</label>
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="provenance"
                            name="provenance"
                            onChange={onChangeEng}
                            value={formDataEng.provenance}
                            placeholder={t_eng('name')}
                        />
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="category"
                            name="category"
                            onChange={onChangeEng}
                            value={formDataEng.category}
                            placeholder={t_eng('category')}
                        />
                        <input
                            type="text"
                            className="form-control"
                            id="alcoholic_strength"
                            name="alcoholic_strength"
                            onChange={onChangeEng}
                            value={formDataEng.alcoholic_strength}
                            placeholder={t_eng('origin')}
                        />
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-8" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label htmlFor="energy_value">{t_eng('PHV_UPPERCASE')}</label>
                        <div className="row">
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="energy_value">{t_eng('energy_value_kj')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="energy_value"
                                    name="energy_value"
                                    onChange={onChangeEng}
                                    value={formDataEng.energy_value}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="energy_value_kcal">{t_eng('energy_value_kcal')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="energy_value_kcal"
                                    name="energy_value_kcal"
                                    onChange={onChangeEng}
                                    value={formDataEng.energy_value_kcal}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="fat">{t_eng('fat')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="fat"
                                    name="fat"
                                    onChange={onChangeEng}
                                    value={formDataEng.fat}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="saturated_fat">{t_eng('saturated_fat')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="saturated_fat"
                                    name="saturated_fat"
                                    onChange={onChangeEng}
                                    value={formDataEng.saturated_fat}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="carbohydrates">{t_eng('carbohydrates')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="carbohydrates"
                                    name="carbohydrates"
                                    onChange={onChangeEng}
                                    value={formDataEng.carbohydrates}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="sugar">{t_eng('sugars')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="sugar"
                                    name="sugar"
                                    onChange={onChangeEng}
                                    value={formDataEng.sugar}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="protein">{t_eng('protein')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="protein"
                                    name="protein"
                                    onChange={onChangeEng}
                                    value={formDataEng.protein}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="salt">{t_eng('salt')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="salt"
                                    name="salt"
                                    onChange={onChangeEng}
                                    value={formDataEng.salt}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label>{t_eng('content_uppercase')}</label>
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="allergens"
                            name="allergens"
                            onChange={onChangeEng}
                            value={formDataEng.allergens}
                            placeholder={t_eng('allergens')}
                            required
                        />
                        <input
                            type="text"
                            className="form-control"
                            id="sulfites"
                            name="sulfites"
                            onChange={onChangeEng}
                            value={formDataEng.sulfites}
                            placeholder={t_eng('sulfites')}
                        />
                    </div>
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_eng('manufacturer')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_name"
                    name="company_name"
                    onChange={onChange}
                    value={formData.company_name}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_eng('address')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_address"
                    name="company_address"
                    onChange={onChange}
                    value={formData.company_address}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_eng('oib')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_oib"
                    name="company_oib"
                    onChange={onChange}
                    value={formData.company_oib}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_eng('phone')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_phone"
                    name="company_phone"
                    onChange={onChange}
                    value={formData.company_phone}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t_eng('email')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_email"
                    name="company_email"
                    onChange={onChange}
                    value={formData.company_email}
                    required
                />
            </div>

            <button type="button" className="btn btn-primary mt-4" onClick={handleSave}>{t_eng('save')}</button>
        </form></>);
    }

    const show_Hr_Input = () => {
        return (<><form>
            <div className="row mb-3">


                <div className="form-group">
                    <label htmlFor="name">{t('product_name_uppercase')}</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        onChange={onChange}
                        value={formData.name}
                        required
                    />
                </div>

            </div>

            <div className="row mb-3">
                <div className="col-md-8" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label htmlFor="ingredients">{t('composition_uppercase')}</label>
                        <textarea
                            className="form-control"
                            id="ingredients"
                            name="ingredients"
                            onChange={onChange}
                            value={formData.ingredients}
                            rows="5"
                        />
                    </div>
                </div>
                <div className="col-md-4" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label>{t('details_uppercase')}</label>
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="provenance"
                            name="provenance"
                            onChange={onChange}
                            value={formData.provenance}
                            placeholder={t('name')}
                        />
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="category"
                            name="category"
                            onChange={onChange}
                            value={formData.category}
                            placeholder={t('category')}
                        />
                        <input
                            type="text"
                            className="form-control"
                            id="alcoholic_strength"
                            name="alcoholic_strength"
                            onChange={onChange}
                            value={formData.alcoholic_strength}
                            placeholder={t('origin')}
                        />
                    </div>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-8" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label htmlFor="energy_value">{t('PHV_UPPERCASE')}</label>
                        <div className="row">
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="energy_value">{t('energy_value_kj')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="energy_value"
                                    name="energy_value"
                                    onChange={onChange}
                                    value={formData.energy_value}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="energy_value_kcal">{t('energy_value_kcal')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="energy_value_kcal"
                                    name="energy_value_kcal"
                                    onChange={onChange}
                                    value={formData.energy_value_kcal}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="fat">{t('fat')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="fat"
                                    name="fat"
                                    onChange={onChange}
                                    value={formData.fat}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="saturated_fat">{t('saturated_fat')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="saturated_fat"
                                    name="saturated_fat"
                                    onChange={onChange}
                                    value={formData.saturated_fat}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="carbohydrates">{t('carbohydrates')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="carbohydrates"
                                    name="carbohydrates"
                                    onChange={onChange}
                                    value={formData.carbohydrates}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="sugar">{t('sugars')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="sugar"
                                    name="sugar"
                                    onChange={onChange}
                                    value={formData.sugar}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="protein">{t('protein')}</label>
                                <input
                                    type="text"
                                    className="form-control mb-2"
                                    id="protein"
                                    name="protein"
                                    onChange={onChange}
                                    value={formData.protein}
                                />
                            </div>
                            <div className="col-6">
                                <label style={{ fontSize: '13px' }} htmlFor="salt">{t('salt')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="salt"
                                    name="salt"
                                    onChange={onChange}
                                    value={formData.salt}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4" style={{ border: "1px solid #ccc", borderRadius: "10px" }}>
                    <div className="form-group">
                        <label>{t('content_uppercase')}</label>
                        <input
                            type="text"
                            className="form-control mb-2"
                            id="allergens"
                            name="allergens"
                            onChange={onChange}
                            value={formData.allergens}
                            placeholder={t('allergens')}
                            required
                        />
                        <input
                            type="text"
                            className="form-control"
                            id="sulfites"
                            name="sulfites"
                            onChange={onChange}
                            value={formData.sulfites}
                            placeholder={t('sulfites')}
                        />
                    </div>
                </div>
            </div>

            <div className="form-group">
                <label htmlFor="name">{t('manufacturer')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_name"
                    name="company_name"
                    onChange={onChange}
                    value={formData.company_name}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t('address')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_address"
                    name="company_address"
                    onChange={onChange}
                    value={formData.company_address}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t('oib')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_oib"
                    name="company_oib"
                    onChange={onChange}
                    value={formData.company_oib}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t('phone')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_phone"
                    name="company_phone"
                    onChange={onChange}
                    value={formData.company_phone}
                    required
                />
            </div>

            <div className="form-group">
                <label htmlFor="name">{t('email')}</label>
                <input
                    type="text"
                    className="form-control"
                    id="company_email"
                    name="company_email"
                    onChange={onChange}
                    value={formData.company_email}
                    required
                />
            </div>

            <button type="button" className="btn btn-primary mt-4" onClick={handleSave}>{t('save')}</button>
        </form></>);

    }

    return (
        <div className="container">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                <div>
                    <h5>{t('add_new_product')}:</h5>
                </div>

                <div>
                    <div style={{ marginTop: "-15px", marginRight: "25px" }}>
                        <select name="switchbox" onChange={(e) => changeLanguageHc(e.target.value)}>
                            <option value="hr">Hr</option>
                            <option value="en">En</option>
                            {/* Add more languages here */}
                        </select>
                    </div>

                </div>

            </div>


            {showWarning() && (
                <p style={{ color: "red" }}>
                    {t('qr_max')}
                </p>
            )}

            {qr_label_list_load ? (
                <div className="spinner-border text-primary mt-4" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            ) : (<>{showProperInputs(lan)}</>


            )}
        </div>
    );
};

export default ProductLabelFormAlt;
