import React, { useState, useEffect } from 'react';
import QRCodeGeneratorLte from './QRCodeGeneratorLte';
import { deleteQrLabelCall } from 'features/qrLabel/deleteQrLabel';
import { updateQrLabelCall } from 'features/qrLabel/updateQrLabel';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { createQrLabelCall } from 'features/qrLabel/createQrLabel';
import i18n from 'i18n';

const FormDetails = ({ data, masked_id, close_modal }) => {
    const dispatch = useDispatch();
    const { qr_account, qr_label_list_load } = useSelector(state => state.user);
    const [selectedRow, setSelectedRow] = useState(null);
    const [editData, setEditData] = useState({});
    const { t } = useTranslation();
    const t_eng = i18n.getFixedT('en');
    const t_hrv = i18n.getFixedT('hr');
    const [lan, setLan] = useState("hr");
    const [hrItem, setHrItem] = useState(null);
    const [enItem, setEnItem] = useState(null);

    useEffect(() => {
        if (data) {
            const hrItem = data.find(item => item.lang === 'hr' && item.masked_id === masked_id);
            const enItem = data.find(item => item.lang === 'en' && item.masked_id === masked_id);
            setHrItem(hrItem || null);
            setEnItem(enItem || null);
        }
    }, [data, masked_id]);

    const handleEdit = (item) => {
        setSelectedRow(item.id);
        setEditData({ ...item });
    };

    const onChange = e => {
        setEditData({ ...editData, [e.target.name]: e.target.value });
    };

    const saveChanges = () => {
        const payload = { ...editData };
        dispatch(updateQrLabelCall(payload));
        setSelectedRow(null);
        close_modal();
    };

    const cancelEdit = () => {
        setSelectedRow(null);
    };

    const handleDelete = (id) => {
        const payload = { id: id };
        dispatch(deleteQrLabelCall(payload));
        setSelectedRow(null);
        close_modal();
    };

    const changeLanguageHc = (lng) => {
        setLan(lng);
    };

    const createNewEntry = (entry) => {
        const { id, ...rest } = entry;
        const payload = { ...rest, lang: lan };
        dispatch(createQrLabelCall(payload));
    };

    const renderForm = (item) => {
        if (!item) return null;
        const currentTranslation = lan === "en" ? t_eng : t_hrv;
        return (
            <div key={item.masked_id} className="card" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginTop: "40px", width: '100%' }}>
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <QRCodeGeneratorLte maskedId={item.masked_id} name={item.name} itemid={item.id} />
                    <form style={{ marginLeft: '10px', marginRight: '10px' }}>
                    {selectedRow === item.id ? (
                            <>
                                <button type="button" className="btn btn-primary mt-4" style={{ marginRight: "50px", marginBottom: "20px" }} onClick={saveChanges}>{t('save')}</button>
                                <button type="button" className="btn btn-danger mt-4" style={{ marginRight: "50px", marginBottom: "20px" }} onClick={() => handleDelete(item.id)}>{t('delete')}</button>
                                <button type="button" className="btn btn-secondary mt-4" style={{ marginRight: "50px", marginBottom: "20px" }} onClick={cancelEdit}>{t('cancel')}</button>
                            </>
                        ) : (
                            <>
                                <button type="button" className="btn btn-primary mt-4" style={{ marginRight: "50px", marginBottom: "20px" }} onClick={() => handleEdit(item)}>{t('edit')}</button>
                            </>
                        )}
                        <div className="row mb-3">
                            <div className="col-12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div className="form-group" style={{ width: "80%" }}>
                                    <label htmlFor="name">{currentTranslation('product_name_uppercase')}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        onChange={onChange}
                                        value={selectedRow === item.id ? editData.name : item.name}
                                        disabled={selectedRow !== item.id}
                                        required
                                    />
                                </div>
                                <div>
                                    <div style={{ marginTop: "-15px" }}>
                                        <select name="switchbox" value={lan} onChange={(e) => changeLanguageHc(e.target.value)}>
                                            <option value="en">En</option>
                                            <option value="hr">Hr</option>
                                            {/* Add more languages here */}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label htmlFor="ingredients">{currentTranslation('composition_uppercase')}</label>
                                    <textarea
                                        className="form-control"
                                        id="ingredients"
                                        name="ingredients"
                                        onChange={onChange}
                                        value={selectedRow === item.id ? editData.ingredients : item.ingredients}
                                        disabled={selectedRow !== item.id}
                                        rows="6"
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{currentTranslation('details_uppercase')}</label>
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        id="provenance"
                                        name="provenance"
                                        onChange={onChange}
                                        value={selectedRow === item.id ? editData.provenance : item.provenance}
                                        placeholder={currentTranslation('name')}
                                        disabled={selectedRow !== item.id}
                                    />
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        id="category"
                                        name="category"
                                        onChange={onChange}
                                        value={selectedRow === item.id ? editData.category : item.category}
                                        placeholder={currentTranslation('category')}
                                        disabled={selectedRow !== item.id}
                                    />
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="alcoholic_strength"
                                        name="alcoholic_strength"
                                        onChange={onChange}
                                        value={selectedRow === item.id ? editData.alcoholic_strength : item.alcoholic_strength}
                                        placeholder={currentTranslation('origin')}
                                        disabled={selectedRow !== item.id}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label htmlFor="energy_value">{currentTranslation('PHV_UPPERCASE')}</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <label style={{ fontSize: '13px' }} htmlFor="energy_value">{currentTranslation('energy_value_kj')}</label>
                                            <input
                                                type="text"
                                                className="form-control mb-2"
                                                id="energy_value"
                                                name="energy_value"
                                                onChange={onChange}
                                                value={selectedRow === item.id ? editData.energy_value : item.energy_value}
                                                disabled={selectedRow !== item.id}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label style={{ fontSize: '13px' }} htmlFor="energy_value_kcal">{currentTranslation('energy_value_kcal')}</label>
                                            <input
                                                type="text"
                                                className="form-control mb-2"
                                                id="energy_value_kcal"
                                                name="energy_value_kcal"
                                                onChange={onChange}
                                                value={selectedRow === item.id ? editData.energy_value_kcal : item.energy_value_kcal}
                                                disabled={selectedRow !== item.id}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label style={{ fontSize: '13px' }} htmlFor="fat">{currentTranslation('fat')}</label>
                                            <input
                                                type="text"
                                                className="form-control mb-2"
                                                id="fat"
                                                name="fat"
                                                onChange={onChange}
                                                value={selectedRow === item.id ? editData.fat : item.fat}
                                                disabled={selectedRow !== item.id}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label style={{ fontSize: '13px' }} htmlFor="saturated_fat">{currentTranslation('saturated_fat')}</label>
                                            <input
                                                type="text"
                                                className="form-control mb-2"
                                                id="saturated_fat"
                                                name="saturated_fat"
                                                onChange={onChange}
                                                value={selectedRow === item.id ? editData.saturated_fat : item.saturated_fat}
                                                disabled={selectedRow !== item.id}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label style={{ fontSize: '13px' }} htmlFor="carbohydrates">{currentTranslation('carbohydrates')}</label>
                                            <input
                                                type="text"
                                                className="form-control mb-2"
                                                id="carbohydrates"
                                                name="carbohydrates"
                                                onChange={onChange}
                                                value={selectedRow === item.id ? editData.carbohydrates : item.carbohydrates}
                                                disabled={selectedRow !== item.id}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label style={{ fontSize: '13px' }} htmlFor="sugar">{currentTranslation('sugars')}</label>
                                            <input
                                                type="text"
                                                className="form-control mb-2"
                                                id="sugar"
                                                name="sugar"
                                                onChange={onChange}
                                                value={selectedRow === item.id ? editData.sugar : item.sugar}
                                                disabled={selectedRow !== item.id}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label style={{ fontSize: '13px' }} htmlFor="protein">{currentTranslation('protein')}</label>
                                            <input
                                                type="text"
                                                className="form-control mb-2"
                                                id="protein"
                                                name="protein"
                                                onChange={onChange}
                                                value={selectedRow === item.id ? editData.protein : item.protein}
                                                disabled={selectedRow !== item.id}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <label style={{ fontSize: '13px' }} htmlFor="salt">{currentTranslation('salt')}</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="salt"
                                                name="salt"
                                                onChange={onChange}
                                                value={selectedRow === item.id ? editData.salt : item.salt}
                                                disabled={selectedRow !== item.id}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <label>{currentTranslation('content_uppercase')}</label>
                                <div className="form-group">
                                    <label style={{ fontSize: '13px' }} htmlFor="allergens">{currentTranslation('allergens')}</label>
                                    <input
                                        type="text"
                                        className="form-control mb-2"
                                        id="allergens"
                                        name="allergens"
                                        onChange={onChange}
                                        value={selectedRow === item.id ? editData.allergens : item.allergens}
                                        disabled={selectedRow !== item.id}
                                        required
                                    />
                                    <label style={{ fontSize: '13px' }} htmlFor="sulfites">{currentTranslation('sulfites')}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="sulfites"
                                        name="sulfites"
                                        onChange={onChange}
                                        value={selectedRow === item.id ? editData.sulfites : item.sulfites}
                                        disabled={selectedRow !== item.id}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="company_name">{currentTranslation('manufacturer')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="company_name"
                                    name="company_name"
                                    onChange={onChange}
                                    value={selectedRow === item.id ? editData.company_name : item.company_name}
                                    disabled={selectedRow !== item.id}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="company_address">{currentTranslation('address')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="company_address"
                                    name="company_address"
                                    onChange={onChange}
                                    value={selectedRow === item.id ? editData.company_address : item.company_address}
                                    disabled={selectedRow !== item.id}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="company_oib">{currentTranslation('oib')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="company_oib"
                                    name="company_oib"
                                    onChange={onChange}
                                    value={selectedRow === item.id ? editData.company_oib : item.company_oib}
                                    disabled={selectedRow !== item.id}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="company_phone">{currentTranslation('phone')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="company_phone"
                                    name="company_phone"
                                    onChange={onChange}
                                    value={selectedRow === item.id ? editData.company_phone : item.company_phone}
                                    disabled={selectedRow !== item.id}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="company_email">{currentTranslation('email')}</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="company_email"
                                    name="company_email"
                                    onChange={onChange}
                                    value={selectedRow === item.id ? editData.company_email : item.company_email}
                                    disabled={selectedRow !== item.id}
                                />
                            </div>
                        </div>
                        {selectedRow === item.id ? (
                            <>
                                <button type="button" className="btn btn-primary mt-4" style={{ marginRight: "50px", marginBottom: "20px" }} onClick={saveChanges}>{t('save')}</button>
                                <button type="button" className="btn btn-danger mt-4" style={{ marginRight: "50px", marginBottom: "20px" }} onClick={() => handleDelete(item.id)}>{t('delete')}</button>
                                <button type="button" className="btn btn-secondary mt-4" style={{ marginRight: "50px", marginBottom: "20px" }} onClick={cancelEdit}>{t('cancel')}</button>
                            </>
                        ) : (
                            <>
                                <button type="button" className="btn btn-primary mt-4" style={{ marginRight: "50px", marginBottom: "20px" }} onClick={() => handleEdit(item)}>{t('edit')}</button>
                                <button type="button" className="btn btn-danger mt-4" style={{ marginRight: "50px", marginBottom: "20px" }} onClick={() => close_modal()}>{t('close')}</button>
                            </>
                        )}
                    </form>
                </div>
            </div>
        );
    };

    return (
        <div>
            {lan === 'hr' && renderForm(hrItem)}
            {lan === 'en' && renderForm(enItem)}
        </div>
    );
};

export default FormDetails;
