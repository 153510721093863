import { createAsyncThunk } from '@reduxjs/toolkit';

export const createQrLabelCall = createAsyncThunk(
    'users/qrlabels/create',
    async (payload, thunkAPI,) => {

        const body = JSON.stringify(payload);
        console.log("createQrLabelCall: " + JSON.stringify(body));


        try {
            const res = await fetch('/api/users/qrlabels/create', {
                method: 'POST',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
                body,
            });

            const data = await res.json();

            if (res.status === 201) {
                return data;

            } else {
                console.log("createQrLabel != 201 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("createQrLabel Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);