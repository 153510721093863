import { createAsyncThunk } from '@reduxjs/toolkit';

export const viewAllQrLabelsCall = createAsyncThunk(
    'users/qrlabels/viewall',
    async (payload, thunkAPI,) => {
        const { id } = payload;


        try {
            const res = await fetch(`/api/users/qrlabels/viewall/${id}/`, {
                method: 'GET',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
            });


            const data = await res.json();

            if (res.status === 200) {
                return data;

            } else {
                console.log("viewAllQrLabels != 200 -----------------------");
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("viewAllQrLabels Error -----------------------");
            console.log(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
    }
);