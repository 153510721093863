import Layout from "components/Layout";
import { Navigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { register } from "features/user";
import { useTranslation } from 'react-i18next';

const RegisterPage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { registered, loading } = useSelector(state => state.user); //u store.js se referiramo kao user

    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
    });

    //destrukturiraj kako bi mogao koristit direektno
    const { first_name, last_name, email, password } = formData;

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });

    }; //state nam je objekt  iz linije 9

    //Kad stisnes gumb okine se submit
    const onSubmit = e => {
        console.log("ON submit action -----------------------");
        //refreshanje forme je default ponašanje
        e.preventDefault();

        //moras dispatchat event
        dispatch(register({ first_name, last_name, email, password }));

    };

    if (registered) return <Navigate to="/login" />;


    // name = "first_name" -> definiran naziv u router/auth/register.js
    // a accessamo ga zbog destrukturiranja i linije 17
    return (
        <Layout title="Auth Site | Register" content="Register page">
            <div className="row" style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh", flexDirection: "column" }}>
                <form className="card"
                    style={{ padding: "20px", maxWidth: "450px" }}
                    action=""
                    onSubmit={onSubmit}>
                    <p className="title">{t('register_user_account')}</p>

                    <div className="input-field">
                        <input className="input"
                            type="text"
                            name="first_name"
                            onChange={onChange}
                            value={first_name}
                            required
                        />
                        <label className="label" htmlFor="input">{t('name_label')}:</label>
                    </div>

                    <div className="input-field">
                        <input className="input"
                            type="text"
                            name="last_name"
                            onChange={onChange}
                            value={last_name}
                            required
                        />
                        <label className="label" htmlFor="input">{t('surname')}:</label>
                    </div>

                    <div className="input-field">
                        <input className="input"
                            type="email"
                            name="email"
                            onChange={onChange}
                            value={email}
                            required
                        />
                        <label className="label" htmlFor="input">Email:</label>
                    </div>

                    <div className="input-field">
                        <input className="input"
                            type="password"
                            name="password"
                            onChange={onChange}
                            value={password}
                            required
                        />
                        <label className="label" htmlFor="input">{t('password')}:</label>
                    </div>
                    {loading ?
                        (
                            <div className="spinner-border text-primary mt-2" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            <button className="btn btn-primary mt-4"> {t('register')} </button>
                        )
                    }
                </form>

                <div className="text-container" style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "60vh", flexDirection: "column" }}>
                    <div className="text" >
                        <h2>{t('ltt')}</h2>
                        <p>
                            {t('lttxt1')}
                        </p>
                        <p>
                            {t('lttxt2')}
                        </p>
                        <p>
                            Contact email: danijel@ordonovus.com
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default RegisterPage;