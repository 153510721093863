import { createAsyncThunk } from '@reduxjs/toolkit';

export const deleteQrLabelCall = createAsyncThunk(
    'users/qrlabels/delete',
    async (payload, thunkAPI) => {
        const { id } = payload;

        try {
            const res = await fetch(`/api/users/qrlabels/delete/${id}/`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/JSON',
                    'Content-Type': 'application/json'
                },
            });


            if (res.status === 204) {
                return null;

            } else {
                const data = await res.json();
                console.log("deleteQrLabel Unexpected response", data);
                return thunkAPI.rejectWithValue(data);
            }

        } catch (err) {
            console.log("deleteQrLabel Error -----------------------", err);
            return thunkAPI.rejectWithValue(err.response ? err.response.data : { error: err.message });
        }
    }
);
