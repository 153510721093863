import { Link, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "features/user";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from 'react-i18next';

const Navbar = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();


    //U storu se zove user, pa se zato zove state.user
    const { isAuthenticated } = useSelector(state => state.user);
    const { user } = useSelector(state => state.user);

    //Napiši ime korisnika
    var name = 'Dashboard';
    if (user !== null) {
        name = user.first_name + " " + user.last_name;
    }

    const authLinks = (
        <>
            <li className="nav-item">
                <a className="nav-link" href="#!" onClick={() => dispatch(logout())}>
                    {t('logoff')}</a>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" to="/dashboard">
                    {name}
                </NavLink>
            </li>
        </>
    );

    //inace cvili da mora imat parent tagove
    const guestLinks = (
        <>
            <li className="nav-item">
                <NavLink className="nav-link" to="/login">
                    {t('login')}
                </NavLink>
            </li>

            <li className="nav-item">
                <NavLink className="nav-link" to="/register">
                    {t('register')}
                </NavLink>
            </li>
        </>

    );

    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="dark" >
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">QR - Codes</Link>
                <button className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/">
                                {t('frontpage')}
                            </NavLink>
                        </li>
                        {isAuthenticated ? authLinks : guestLinks}
                        <LanguageSwitcher />

                    </ul>
                </div>
            </div>
        </nav>
    );

};

export default Navbar;