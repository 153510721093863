import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import aboutCompany1 from './assets/img/about-company-2.jpg';
import img1 from './assets/img/1.jpg';
import img2 from './assets/img/2.jpg';
import img3 from './assets/img/3.jpg';
import AOS from 'aos';
import GLightbox from 'glightbox';

import LanguageSwitcher from 'components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

const ServiceDetailsPage = () => {
    const { t } = useTranslation();
    const { language } = useSelector(state => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        content_manager()
    }, [dispatch, language]);


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialService = queryParams.get('section') || 'qr';

    const [selectedService, setSelectedService] = useState(initialService);

    useEffect(() => {
        const toggleScrolled = () => {
            const selectBody = document.querySelector('body');
            const selectHeader = document.querySelector('#header');
            if (!selectHeader.classList.contains('scroll-up-sticky') && !selectHeader.classList.contains('sticky-top') && !selectHeader.classList.contains('fixed-top')) return;
            window.scrollY > 100 ? selectBody.classList.add('scrolled') : selectBody.classList.remove('scrolled');
        };

        document.addEventListener('scroll', toggleScrolled);
        window.addEventListener('load', toggleScrolled);

        const mobileNavToggleBtn = document.querySelector('.mobile-nav-toggle');
        const mobileNavToogle = () => {
            document.querySelector('body').classList.toggle('mobile-nav-active');
            mobileNavToggleBtn.classList.toggle('bi-list');
            mobileNavToggleBtn.classList.toggle('bi-x');
        };
        if (mobileNavToggleBtn) {
            mobileNavToggleBtn.addEventListener('click', mobileNavToogle);
        }

        document.querySelectorAll('#navmenu a').forEach(navmenu => {
            navmenu.addEventListener('click', () => {
                if (document.querySelector('.mobile-nav-active')) {
                    mobileNavToogle();
                }
            });
        });

        document.querySelectorAll('.navmenu .toggle-dropdown').forEach(navmenu => {
            navmenu.addEventListener('click', function (e) {
                e.preventDefault();
                this.parentNode.classList.toggle('active');
                this.parentNode.nextElementSibling.classList.toggle('dropdown-active');
                e.stopImmediatePropagation();
            });
        });

        const preloader = document.querySelector('#preloader');
        if (preloader) {
            window.addEventListener('load', () => {
                preloader.remove();
            });
        }

        const scrollTop = document.querySelector('.scroll-top');
        const toggleScrollTop = () => {
            if (scrollTop) {
                window.scrollY > 100 ? scrollTop.classList.add('active') : scrollTop.classList.remove('active');
            }
        };
        scrollTop?.addEventListener('click', (e) => {
            e.preventDefault();
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        });

        window.addEventListener('load', toggleScrollTop);
        document.addEventListener('scroll', toggleScrollTop);

        function aosInit() {
            AOS.init({
                duration: 600,
                easing: 'ease-in-out',
                once: true,
                mirror: false,
            });
        }
        window.addEventListener('load', aosInit);

        const glightbox = GLightbox({
            selector: '.glightbox',
        });

        document.querySelectorAll('.faq-item h3, .faq-item .faq-toggle').forEach((faqItem) => {
            faqItem.addEventListener('click', () => {
                faqItem.parentNode.classList.toggle('faq-active');
            });
        });

        // Cleanup event listeners on component unmount
        return () => {
            document.removeEventListener('scroll', toggleScrolled);
            window.removeEventListener('load', toggleScrolled);
            mobileNavToggleBtn?.removeEventListener('click', mobileNavToogle);
            scrollTop?.removeEventListener('click', toggleScrollTop);
            document.removeEventListener('scroll', toggleScrollTop);
        };
    }, []);

    const content_generating = () => (
        <div className="col-lg-8 ps-lg-5" data-aos="fade-up" data-aos-delay="200">
            <h3>{t("generate_qr_title")}</h3>
            <img src={img2} alt="Services" className="img-fluid services-img" />

            <p>{t("generate_qr1")}</p>
            <ul>
                <li><i className="bi bi-check-circle"></i> <span>{t("generate_qr2")}</span></li>
                <li><i className="bi bi-check-circle"></i> <span>{t("generate_qr3")}</span></li>
                <li><i className="bi bi-check-circle"></i> <span>{t("generate_qr4")}</span></li>
            </ul>
            <p>{t("generate_qr5")}</p>

        </div>
    );

    const content_creating = () => (
        <div className="col-lg-8 ps-lg-5" data-aos="fade-up" data-aos-delay="200">
            <h3>{t('cdl_title')}</h3>
            <img src={aboutCompany1} alt="Creating Digital Labels" className="img-fluid services-img" />

            <p>{t('cdl_1')}</p>
            <ul>
                <li><i className="bi bi-check-circle"></i> <span>{t('cdl_2')}</span></li>
                <li><i className="bi bi-check-circle"></i> <span>{t('cdl_3')}</span></li>
                <li><i className="bi bi-check-circle"></i> <span>{t('cdl_4')}</span></li>
            </ul>
            <p>{t('cdl_5')}</p>
        </div>
    );

    const content_support = () => (
        <div className="col-lg-8 ps-lg-5" data-aos="fade-up" data-aos-delay="200">
            <h3>{t('sfml_title')}</h3>
            <img src={img3} alt="Support for Multiple Languages" className="img-fluid services-img" />

            <p>{t('sfml_1')}</p>
            <ul>
                <li><i className="bi bi-check-circle"></i> <span>{t('sfml_2')}</span></li>
                <li><i className="bi bi-check-circle"></i> <span>{t('sfml_3')}</span></li>
                <li><i className="bi bi-check-circle"></i> <span>{t('sfml_4')}</span></li>
            </ul>
            <p>{t('sfml_5')}</p>
        </div>
    );

    const content_regulatory = () => (
        <div className="col-lg-8 ps-lg-5" data-aos="fade-up" data-aos-delay="200">
            <h3>{t('reg_comp_title')}</h3>
            <img src={aboutCompany1} alt="Regulatory Compliance" className="img-fluid services-img" />

            <p>{t('reg_comp1')}</p>
            <ul>
                <li><i className="bi bi-check-circle"></i> <span>{t('reg_comp2')}</span></li>
                <li><i className="bi bi-check-circle"></i> <span>{t('reg_comp3')}</span></li>
                <li><i className="bi bi-check-circle"></i> <span>{t('reg_comp4')}</span></li>
                <li><i className="bi bi-check-circle"></i> <span>{t('reg_comp5')}</span></li>
                <li><i className="bi bi-check-circle"></i> <span>{t('reg_comp6')}</span></li>
            </ul>
            <p>{t('reg_comp7')}</p>
            <p>{t('reg_comp8')}</p>
        </div>
    );

    const content_qr = () => (
        <div className="col-lg-8 ps-lg-5" data-aos="fade-up" data-aos-delay="200">
            <h3>{t('qr_hosting_title')}</h3>
            <img src={img1} alt="QR Hosting" className="img-fluid services-img" />

            <p>{t('qr_hosting_1')}</p>
            <ul>
                <li><i className="bi bi-check-circle"></i> <span>{t('qr_hosting_2')}</span></li>
                <li><i className="bi bi-check-circle"></i> <span>{t('qr_hosting_3')}</span></li>
                <li><i className="bi bi-check-circle"></i> <span>{t('qr_hosting_4')}</span></li>
            </ul>
            <p>{t('qr_hosting_5')}</p>
        </div>
    );


    const content_manager = () => {
        switch (selectedService) {
            case 'generating':
                return content_generating();
            case 'creating':
                return content_creating();
            case 'support':
                return content_support();
            case 'regulatory':
                return content_regulatory();
            case 'qr':
                return content_qr();
            default:
                return content_generating();
        }
    };

    return (
        <div className="service-details-page">
            {/* Header */}
            <header id="header" className="header d-flex align-items-center sticky-top">
                <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
                    <a href="https://www.qr.ordonovus.com" className="logo d-flex align-items-center">
                        <h1 className="sitename"><span>Qr</span>Codes</h1>
                    </a>
                    <nav id="navmenu" className="navmenu">
                        <ul>
                            <li><a href="https://www.qr.ordonovus.com/#hp">{t("frontpage")}</a></li>
                            <li><a href="https://www.qr.ordonovus.com/#about">{t("about_product")}</a></li>
                            <li><a href="https://www.qr.ordonovus.com/#services" className="active">{t("services")}</a></li>
                            <li><a href="https://www.qr.ordonovus.com/#features">{t("features")}</a></li>
                            <li><a href="https://www.qr.ordonovus.com/#pricing">{t("pricing")}</a></li>
                            <li><a href="https://www.qr.ordonovus.com/#contact">{t("contact")}</a></li>
                            <li>
                                <NavLink className="nav-link" to="/login">
                                    {t("login")}
                                </NavLink></li>
                            <LanguageSwitcher />
                        </ul>
                        <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
                    </nav>
                </div>
            </header>

            {/* Main Content */}
            <main className="main">
                {/* Page Title */}
                <div className="page-title" data-aos="fade">
                    <div className="heading">
                        <div className="container">
                            <div className="row d-flex justify-content-center text-center">
                                <div className="col-lg-8">
                                    <h1>{t('service_details_title')}</h1>
                                    <p className="mb-0">{t('service_details_text')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="breadcrumbs">
                        <div className="container">
                            <ol>
                                <li><NavLink className="nav-link" to="/">{t("frontpage")}</NavLink></li>
                                <li className="current">{t('service_details')}</li>
                            </ol>
                        </div>
                    </nav>
                </div>

                {/* Service Details Section */}
                <section id="service-details" className="service-details section">
                    <div className="container">
                        <div className="row gy-5">
                            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                                <div className="service-box">
                                    <h4>{t('services_list')}</h4>
                                    <div className="services-list">
                                        <a href="#" className={selectedService === 'qr' ? 'active' : ''} onClick={() => setSelectedService('qr')}><i className="bi bi-arrow-right-circle"></i><span>{t('qr_hosting')}</span></a>
                                        <a href="#" className={selectedService === 'generating' ? 'active' : ''} onClick={() => setSelectedService('generating')}><i className="bi bi-arrow-right-circle"></i><span>{t('generating_qr')}</span></a>
                                        <a href="#" className={selectedService === 'creating' ? 'active' : ''} onClick={() => setSelectedService('creating')}><i className="bi bi-arrow-right-circle"></i><span>{t('creating_a_digital_label')}</span></a>
                                        <a href="#" className={selectedService === 'support' ? 'active' : ''} onClick={() => setSelectedService('support')}><i className="bi bi-arrow-right-circle"></i><span>{t('multiple_langs')}</span></a>
                                        <a href="#" className={selectedService === 'regulatory' ? 'active' : ''} onClick={() => setSelectedService('regulatory')}><i className="bi bi-arrow-right-circle"></i><span>{t('regulatory_compl')}</span></a>
                                    </div>
                                </div>

                                <div className="service-box">
                                    <h4>{t('download_catalog')}</h4>
                                    <div className="download-catalog">
                                        <a href="#"><i className="bi bi-filetype-pdf"></i><span>Catalog PDF</span></a>
                                        <a href="#"><i className="bi bi-file-earmark-word"></i><span>Catalog DOC</span></a>
                                    </div>
                                </div>

                                <div className="help-box d-flex flex-column justify-content-center align-items-center">
                                    <i className="bi bi-headset help-icon"></i>
                                    <h4>{t('have_question_qm')}</h4>
                                    <p className="d-flex align-items-center mt-2 mb-0"><i className="bi bi-telephone me-2"></i> <span>+38592 286 2492</span></p>
                                    <p className="d-flex align-items-center mt-1 mb-0"><i className="bi bi-envelope me-2"></i> <a href="mailto:danijel@ordonovus.com">danijel@ordonovus.com</a></p>
                                    <p className="d-flex align-items-center mt-1 mb-0"><i className="bi bi-envelope me-2"></i> <a href="mailto:kristian@ordonovus.com">kristian@ordonovus.com</a></p>
                                </div>
                            </div>

                            {content_manager()}
                        </div>
                    </div>
                </section>
            </main>

            {/* Footer */}
            <footer id="footer" className="footer light-background">
                <div className="container">
                    <div className="copyright text-center">
                        <p>© <span>Copyright</span> <strong className="px-1 sitename">Ordo Novus</strong> <span>All Rights Reserved</span></p>
                    </div>
                    <div className="social-links d-flex justify-content-center">
                        <a href="#"><i className="bi bi-twitter-x"></i></a>
                        <a href="#"><i className="bi bi-facebook"></i></a>
                        <a href="#"><i className="bi bi-instagram"></i></a>
                        <a href="#"><i className="bi bi-linkedin"></i></a>
                    </div>
                    <div className="credits">
                        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                    </div>
                </div>
            </footer>

            {/* Scroll Top */}
            <a href="#" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center">
                <i className="bi bi-arrow-up-short"></i>
            </a>

            {/* Preloader <div id="preloader"></div>*/}

        </div>
    );
};

export default ServiceDetailsPage;
