//REDUX  https://redux-toolkit.js.org/introduction/getting-started
import { configureStore } from "@reduxjs/toolkit";
import userReducer from 'features/user'

export const store = configureStore({
    reducer: {
        user: userReducer,
    },

    devTools: process.env.NODE_ENV !== 'production'
});

//import { useSelector } from "react-redux";
//const user = useSelector(state => state.user)


//const initialState = {
//
//}