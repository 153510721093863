import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { publicViewQrLabelsCall } from "features/qrLabel/publicViewQrLabels";
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from 'components/LanguageSwitcher';


const ItemAlt = () => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const { id, sig } = useParams();
    const { public_label, no_public_label, language, qr_label_list_load } = useSelector(state => state.user);
    const { t } = useTranslation();
    const [lan, setLan] = useState("hr");

    useEffect(() => {
        const payload = {
            id: id,
            mask: sig,
        }
        dispatch(publicViewQrLabelsCall(payload));
    }, [dispatch, id, sig]);

    useEffect(() => {
        const browserLanguage = navigator.language || navigator.userLanguage;
        if (browserLanguage.startsWith('hr') && language === '') {
            setLan('hr');

        } else if (language === '') {
            setLan('en');
        }

    }, [i18n]);

    useEffect(() => {
        console.log("language: " + JSON.stringify(language));
        if (language) {
            setLan(language);
        }
    }, [dispatch, language]);


    const [formData, setFormData] = useState({
        name: "",
        allergens: "",
        energy_value: "",
        energy_value_kcal: "",
        fat: "",
        saturated_fat: "",
        carbohydrates: "",
        sugar: "",
        protein: "",
        salt: "",
        category: "",
        alcoholic_strength: "",
        provenance: "",
        producer_info: "",
        net_content: "",
        sugar_content: "",
        durability_date: "",
        ingredients: "",
        nutritional_info: "",
        sulfites: "",
        company_name: "",
        company_address: "",
        company_oib: "",
        company_phone: "",
        company_email: "",
    });

    useEffect(() => {
        if (public_label) {
            const filteredLabel = public_label.find(label => label.lang === lan);
            //console.log("filteredLabel: " + JSON.stringify(filteredLabel));
            if (filteredLabel) {
                setFormData({
                    name: filteredLabel?.name ?? "",
                    allergens: filteredLabel?.allergens ?? "",
                    energy_value: filteredLabel?.energy_value ?? "",
                    energy_value_kcal: filteredLabel?.energy_value_kcal ?? "",
                    fat: filteredLabel?.fat ?? "",
                    saturated_fat: filteredLabel?.saturated_fat ?? "",
                    carbohydrates: filteredLabel?.carbohydrates ?? "",
                    sugar: filteredLabel?.sugar ?? "",
                    protein: filteredLabel?.protein ?? "",
                    salt: filteredLabel?.salt ?? "",
                    category: filteredLabel?.category ?? "",
                    alcoholic_strength: filteredLabel?.alcoholic_strength ?? "",
                    provenance: filteredLabel?.provenance ?? "",
                    producer_info: filteredLabel?.producer_info ?? "",
                    net_content: filteredLabel?.net_content ?? "",
                    sugar_content: filteredLabel?.sugar_content ?? "",
                    durability_date: filteredLabel?.durability_date ?? "",
                    ingredients: filteredLabel?.ingredients ?? "",
                    nutritional_info: filteredLabel?.nutritional_info ?? "",
                    sulfites: filteredLabel?.sulfites ?? "",
                    company_name: filteredLabel?.company_name ?? "",
                    company_address: filteredLabel?.company_address ?? "",
                    company_oib: filteredLabel?.company_oib ?? "",
                    company_phone: filteredLabel?.company_phone ?? "",
                    company_email: filteredLabel?.company_email ?? "",
                });
            }
        }
    }, [public_label, lan]);

    const validateParams = () => {
        return id.length === 0 || sig.length === 0;
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            {no_public_label || validateParams() ? (
                <div className="form-control-wide" style={{ width: "60%", maxWidth: "800px", display: 'flex', justifyContent: 'center' }}>
                    Invalid entry
                </div>
            ) : (
                <div className="form-control-wide" style={{ width: "100%", maxWidth: "800px", display: 'flex', justifyContent: 'center' }}>
                    <div className="product-label-form" >
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div>
                                <h5>{t('product_info')}:</h5>
                            </div>
                            <div style={{ marginTop: "-15px" }}>
                                <LanguageSwitcher />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <div className="form-group">
                                    <label style={{ fontSize: "20px", fontWeight: "bold" }}>{t('product_name_uppercase')}:</label>
                                    <div style={{ fontSize: "20px" }}>{formData.name}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: "0px" }}>
                            <div className="col-md-8" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                                <div style={{ border: "1px solid #ccc", borderRadius: "10px", marginBottom: "20px", paddingLeft: "10px", height: "90%" }}>
                                    <div className="form-group" style={{ fontSize: "14px", paddingTop: "10px", paddingBottom: "20px" }}>
                                        <label style={{ fontWeight: "bold", marginBottom: "10px", fontSize: "20px" }}>{t('composition_uppercase')}:</label>
                                        <div style={{ wordBreak: "break-word", paddingRight: "8px" }}>{formData.ingredients}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                <div style={{ border: "1px solid #ccc", borderRadius: "10px", marginBottom: "20px", paddingRight: "10px" }}>
                                    <div className="form-group" style={{ fontSize: "14px", marginLeft: "10px", paddingTop: "10px" }}>
                                        <label style={{ fontWeight: "bold", marginBottom: "10px", fontSize: "20px" }}>{t('details_uppercase')}:</label>
                                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", fontWeight: "bold" }}><span>{t('name')}: </span> </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}><span>{formData.provenance}</span></div>
                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", fontWeight: "bold" }}><span>{t('category')}:</span> </div>
                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}> <span>{formData.category}</span></div>
                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}><span style={{ fontWeight: "bold" }}>{t('origin')}:</span> <span>{formData.alcoholic_strength}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginBottom: "0px" }}>
                            <div className="col-md-8" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                                <div style={{ border: "1px solid #ccc", borderRadius: "10px", marginBottom: "20px", padding: "10px" }}>
                                    <label style={{ fontWeight: "bold", marginBottom: "10px", fontSize: "20px", marginBlock: "10px" }}>{t('PHV_UPPERCASE')}:</label>
                                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            <span>{t('energy')}:</span>
                                            <span>{formData.energy_value} kJ / {formData.energy_value_kcal} kcal</span>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            <span>{t('fat')}:</span>
                                            <span>{formData.fat}</span>
                                        </div>
                                        <div style={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr auto',
                                            alignItems: 'center',
                                            width: '100%'
                                        }}>
                                            <span style={{ marginLeft: "10px", whiteSpace: 'pre-wrap' }}>
                                                {t('saturated_fat')}:
                                            </span>
                                            <span style={{ marginLeft: '10px' }}>
                                                {formData.saturated_fat}
                                            </span>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            <span>{t('carbohydrates')}:</span>
                                            <span>{formData.carbohydrates}</span>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            <span style={{ marginLeft: "10px" }}>{t('sugars')}:</span>
                                            <span>{formData.sugar}</span>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            <span>{t('protein')}:</span>
                                            <span>{formData.protein}</span>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            <span>{t('salt')}:</span>
                                            <span>{formData.salt}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                                <div style={{ border: "1px solid #ccc", borderRadius: "10px", marginBottom: "20px", paddingRight: "10px", height: "145px" }}>
                                    <div className="form-group" style={{ fontSize: "14px", marginLeft: "10px", paddingTop: "10px" }}>
                                        <label style={{ fontWeight: "bold", fontSize: "20px", marginBlock: "10px" }}>{t('content_uppercase')}:</label>
                                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}><span style={{ marginLeft: "5px", fontWeight: "bold" }}>{t('allergens')}</span> <span>{formData.allergens}</span></div>
                                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }} ><span>{t('sulfites')}: </span> <span>{formData.sulfites}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <label style={{ fontSize: "14px", fontWeight: "bold" }}>{t('manufacturer')}:</label>
                        <div style={{ paddingLeft: "20px", marginBottom: "10px", backgroundColor: "lightgray" }}>{formData.company_name}</div>

                        <label style={{ fontSize: "14px", fontWeight: "bold" }}>{t('address')}:</label>
                        <div style={{ paddingLeft: "20px", marginBottom: "10px", backgroundColor: "lightgray" }}>{formData.company_address}</div>

                        <label style={{ fontSize: "14px", fontWeight: "bold" }}>{t('oib')}:</label>
                        <div style={{ paddingLeft: "20px", marginBottom: "10px", backgroundColor: "lightgray" }}>{formData.company_oib}</div>

                        <label style={{ fontSize: "14px", fontWeight: "bold" }}>{t('phone')}:</label>
                        <div style={{ paddingLeft: "20px", marginBottom: "10px", backgroundColor: "lightgray" }}>{formData.company_phone}</div>

                        <label style={{ fontSize: "14px", fontWeight: "bold" }}>{t('email')}:</label>
                        <div style={{ paddingLeft: "20px", marginBottom: "10px", backgroundColor: "lightgray" }}>{formData.company_email}</div>
                    </div>

                    <div style={{ fontSize: "12px" }}>{t('disclamer')}</div>
                </div>
            )}
        </div>
    );
};

export default ItemAlt;
